<template>
  <div class="d-flex align-center">
    <v-autocomplete
      v-if="!loading"
      v-model="selectedCliente"
      :label="$tc('global.cliente')"
      :items="clientes"
      :loading="buscandoClientes"
      :search-input.sync="searchCliente"
      dense
      outlined
      flat
      solo
      hide-details
      item-text="nome"
      item-value="id"
      :placeholder="$tc('global.buscarCliente')"
      append-icon=""
      full-width
      return-object
      no-filter
      :readonly="readonly"
      :clearable="!readonly"
      ><template v-slot:selection="data">
        <div class="pa-0">
          <div class="">{{ data.item.nome }}</div>
        </div>
      </template>
    </v-autocomplete>
    <div class="ml-4">
      <v-btn
        v-if="selectedCliente"
        height="38px"
        width="48px"
        color="#4CAF50"
        x-small
        @click="goToEditCliente"
      >
        <v-icon class="white--text">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-else
        depressed
        height="38px"
        width="48px"
        color="#4CAF50"
        x-small
        @click="goToAddCliente"
      >
        <v-icon class="white--text">mdi-plus</v-icon>
      </v-btn>
    </div>
    <DialogCliente
      v-if="dialogCliente"
      :dialogCliente.sync="dialogCliente"
      :edit="edit"
      :cliente_id="selectedCliente ? selectedCliente.id : null"
      @fetch-clientes="buscaClientes"
    />
  </div>
</template>

<script>
import { searchClientesCombobox } from "@/api/clientes/clientes.js";
export default {
  name: "SearchClientes",

  components: {
    DialogCliente: () => import("./DialogCliente.vue"),
  },

  props: {
    cliente_sync: {
      type: [Object, String],
    },
    cliente_id: {
      type: [Number, String],
      default: null,
    },
    cliente_nome: {
      type: [String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      selectedCliente: null,
      clientes: [],
      buscandoClientes: false,
      searchCliente: "",
      debounce: null,
      dialogCliente: false,
    };
  },

  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        this.$emit("update:cliente_sync", this.selectedCliente);
      } else {
        this.$emit("update:cliente_sync", null);
      }
    },
    searchCliente() {
      if (!this.searchCliente || this.buscandoClientes) return;
      this.buscaClientes();
    },
  },

  methods: {
    async searchClientesCombobox(filtro) {
      this.buscandoClientes = true;

      await searchClientesCombobox(filtro).then((response) => {
        this.clientes = response;
        if (this.cliente_id) {
          this.selectedCliente = this.clientes.find((item) => {
            return item.id == this.cliente_id;
          });
        }
      });
      this.buscandoClientes = false;
    },

    async buscaClientes(cliente_id) {
      const filtro = `?search=${this.searchCliente}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.searchClientesCombobox(this.searchCliente ? filtro : "");
        if (cliente_id) {
          this.selectedCliente = this.clientes.find((item) => {
            return item.id == cliente_id;
          });
        }
      }, 500);
    },

    goToAddCliente() {
      this.edit = false;
      this.dialogCliente = true;
    },

    goToEditCliente() {
      this.edit = true;
      this.dialogCliente = true;
    },
  },

  async mounted() {
    if (this.cliente_nome) {
      await this.searchClientesCombobox(`?search=${this.cliente_nome}`);
    }
    this.loading = false;
  },
};
</script>

<style></style>
